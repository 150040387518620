import type { GetServerSideProps } from 'next';
import { Component } from 'react';
import { serialize } from 'cookie';
import type { DefaultPageProps } from '../utils/pages';

class LoginPage extends Component<DefaultPageProps> {
  render() {
    return <>Set your xo-session-token env variable</>;
  }
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { res } = context;
  const { APP_ENV, xoSessionToken } = process.env;

  if (APP_ENV === 'development' && xoSessionToken) {
    res.setHeader(
      'Set-Cookie',
      serialize('xo-session-token', xoSessionToken, {
        maxAge: 60 * 60 * 24 * 7 * 365,
        path: '/',
        domain: '.theknot.com',
      })
    );

    res.setHeader('location', '/dashboard');
    res.statusCode = 302;
    res.end();
    return { props: { cool: true } };
  }
  return { props: { cool: false } };
};

export default LoginPage;
